.subp.warner .page {
	padding-top: 80px;
}

.cover {
	height: 150px;
}

.subp.warner .cover {
	background-color: #fff;
	background-image: url('../../img/portfolio/warner/warner_lg_2.jpg');
	background-size: cover;
}

.logo {
	height: 150px;
	width: 150px;
	border-radius: 50%;
	box-shadow: 0px 0px 10px;
	position: absolute;
	top: 75px;
	left: calc(50% - 75px);
}

.visit {
	text-align: center;
	margin-top: 0;
}

.visit a {
	font-style: italic;
}

.white-bg .portfolio-h2 {
	font-size: 36px;
}

.subp #portfolio-h1 {
  color: #333;
	font-size: 36px;
	margin-top: 5px;
	margin-bottom: 12px;
	text-align: center;
}