code {
  display: block;
	background: rgba(0, 0, 0, .05);
	padding: 20px;
	margin: 0;
  color: #333;
  line-height: 1.2;
}

.step {
  display: block;
	font-weight: bold;
	font-size: 19px;
}

.time-stamp {
	padding-bottom: 16px;
}

.time-stamp p {
	margin: 0;
	font-style: italic;
	color: gray;
}