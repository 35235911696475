body {
	font-family: Helvetica, sans-serif;
  font-size: 16px;
  color: #333;
}

.content {
	position: relative;
  background-color: #00787a;
  min-height: calc(100% - 56px);
}

.container,
.page {
	padding: 0 25px 50px;
}

h1 {
  font-size: 64px;
  font-family: 'Poiret One', sans-serif;
  color: #fff;
  text-align: center;
}

h2 {
	font-family: 'Sacramento', cursive;
  font-size: 40px;
}

h3 {
  font-size: 28px;
  font-family: 'Poiret One', sans-serif;
}

a {
	color: #333;
	text-decoration: underline;
}

.click {
	font-style: italic;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 72px;
  }
}

@media only screen and (min-width: 992px) {
  .container {
		margin: 0 auto;
		width: 70%;
		max-width: 960px;
	}

  .page {
    margin: 0 auto;
    padding: 0 0 50px;
		width: 80%;
		max-width: 960px;
  }

  h1 {
    font-size: 96px;
  }

  h2 {
    font-size: 48px;
  }

	h3 {
		font-size: 28px;
	}
}

/*
 *
 * SUBPAGES
 *
 */
 .sub-page {
	background-color: #00787a;
}

.sub-page h1 {
	text-align: center;
	color: #fff;
	margin: 0;
	padding: 0;
}

.white-bg {
	margin-top: 10px;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 0px 5px gray;
	background-color: #fff;
}

.white-bg h2 {
	border-bottom: 1px solid #333;
	margin-bottom: 10px;
	margin-top: 20px;
}

.sub-page .home-page-link, .home-page-link, .notice {
	text-align: center;
}