main {
  position: relative;
}

/*
 *
 * NAVIGATION
 *
 */
.navbar {
  padding: 0;
  background-color: #fff !important;
}

.navbar-nav {
  flex-direction: row;
  padding-left: 25px;
}

.nav a {
	display: flex;
	margin: 0;
	padding: 16px 8px;
	text-decoration: none;
	color: #333;
}

.nav a:hover,
.nav a:focus {
	text-decoration: underline;
	color: #333;
}

.nav a.active {
  font-weight: bold;
  background-color: #ff8785;
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .nav a {
    padding: 16px;
  }
}

@media only screen and (min-width: 992px) {
  .navbar-nav {
    margin: 0 auto;
    padding: 0;
		width: 80%;
		max-width: 960px;
  }
}